import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

const RefundsReturnsPage = () => {
  return (
    <Layout>
      <SEO title="Refunds & Returns" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>REFUNDS & RETURNS</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section" id="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="content">
                  <div class="row">
                    <div class="col-md-12 mb-5">
                      <p>
                        We accommodate most returns, but only within 30 days of
                        receiving your item. If more than 30 days have gone by
                        since you received your order, unfortunately, we are
                        unable to offer a return or exchange.
                      </p>
                      <p>
                        If you would like to return or exchange an item, it must
                        be in the exact same condition in which you received the
                        item and must also be in the original packaging in which
                        the item was shipped.
                      </p>
                      <p>
                        Please also retain your receipt or proof of purchase to
                        complete the return.
                      </p>
                      <p>
                        Once your item has been received and inspected, a member
                        of our team will notify you via email that your item has
                        been received and is being processed for return. You
                        will also be notified in the same email as to whether
                        your return has been approved or rejected. If you refund
                        is rejected, you can elect to have us destroy the item
                        or items, or pay to have them returned to you, where
                        applicable.
                      </p>
                      <p>
                        If your return is approved, you will receive your funds
                        back, the same way in which they were received. We ask
                        that you allow 7-14 days for the refund to process. If
                        it has been longer than 7-14 since your refund was
                        issued, please first contact your bank or financial
                        institution in order to verify as to whether or not the
                        funds have been received or are on the way to your
                        account.
                      </p>
                      <p>
                        If you are still unsuccessful after this process, please
                        do not hesitate to contact us via {""}
                        <a
                          href="mailto:help@heavenly-candy.com"
                          target="_blank"
                          rel="no referrer"
                          className="text-purple"
                        >
                          help@heavenly-candy.com
                        </a>{" "}
                        and member of our team will be happy to help.
                      </p>
                      <p>
                        Please note you are responsible for all postage when
                        mailing an item back.
                      </p>
                      <p>
                        For replacements, please note that we only replace or
                        exchange items which are damaged or defective. If this
                        is the case, please contact us at {""}
                        <a
                          href="mailto:help@heavenly-candy.com"
                          target="_blank"
                          rel="no referrer"
                          className="text-purple"
                        >
                          help@heavenly-candy.com.
                        </a>{" "}
                        For all gifts purchased by somebody else and sent to
                        you, you will receive a merchandise credit for the value
                        of the item or items which you are returning. Please
                        note, if the item or items have been on sale within the
                        past year, you will receive the sale price for the value
                        of the item or items. Once the item has been received
                        and processed by our team, merchandise cards will be
                        sent to you.
                      </p>
                      <div class="col mt-5">
                        <div class="tabs text-left">
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd1"
                              name="rd"
                            />
                            <label class="tab-label" for="rd1">
                              For all returns within the United States, please
                              send all items to:
                            </label>
                            <div class="tab-content">
                              <ul className="list-style-none">
                                <li>Heavenly-Candy</li>

                                <li>Returns Processing Center </li>

                                <li>Address, Address, Address, </li>

                                <li>Boulder, Colorado, 88222 </li>
                              </ul>
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd2"
                              name="rd"
                            />
                            <label class="tab-label" for="rd2">
                              For all returns within the United States, please
                              send all items to:
                            </label>
                            <div class="tab-content">
                              <ul className="list-style-none">
                                <li>Heavenly-Candy</li>

                                <li>Returns Processing Centre </li>

                                <li>Address, Address, Address, </li>

                                <li>Bedford, Bedfordshire , MK42994 </li>
                              </ul>
                            </div>
                          </div>

                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd99"
                              name="rd"
                            />
                            <label for="rd99" class="tab-close">
                              Close others &times;
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-5">
                        <p>
                          All shipping costs are non-refundable, and you will be
                          responsible for sending any and all items back. If you
                          receive a refund, the cost of the return shipping will
                          be deducted from your refund.
                        </p>
                        <p>
                          We strongly recommend a recorded or trackable shipping
                          service be used when sending back an item, or you
                          purchase shipping insurance, as couriers may lose
                          packages from time to time. We are not responsible for
                          any lost packages send to us. If your package goes
                          missing while being shipped to you, we will make every
                          effort to track down the item or will be in contact to
                          make it right for you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RefundsReturnsPage
